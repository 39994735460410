@font-face {
  font-family: century-gothic;
  src: url(./fonts/century-gothic/century-gothic.ttf);
  font-size: unset;
}
@font-face {
  font-family: century-gothic-bold;
  src: url(./fonts/century-gothic//century-gothic-bold.ttf);
  font-weight: bold;
}
body {
  min-height: 100%;
  font-size: 20px;
  font-family: century-gothic-bold;
}
.App {
  height: 100vh;
  width: 100vw;
  max-width: 500px;
  margin: auto;
  text-align: center;
}
.header {
  height: 20vh;
  display: flex;
  flex-direction: column;
}
.logo {
  padding: 0 20px;
  max-height: 100%;
  margin-top: 20px;
}
.message {
  padding: 10px 20px;
}
.subMessage {
  padding: 10px 20px;
  font-size: 17px;
}
.item {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.footer {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
}
.link {
  margin-bottom: 20px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.error {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
}
#barcode {
  max-width: 90vw;
}
#qrcode {
  max-width: 90vw;
}
.tegra {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.tegraLogo {
  height: 50px;
  width: 50px;
}
.tegraText {
  font-family: century-gothic;
  margin: 0;
  margin-bottom: -10px;
  font-size: 16px;
}